<template>
  <div class="about container-fluid text-left">
    <h1>FAQ</h1>
    <br/>
    <h4>How do NFTs get “carbonized”?</h4>
    <p>
      A carbonized NFT could take many different forms. In the case of The Genesis Stone, the buyer mints the NFT using Base Carbon Tonne tokens (BCT) and then Carbonized.xyz bonds the BCT at KlimaDAO in exchange for KLIMA. So the buyer receives the NFT, the creator gets KLIMA (in this case Carbonized.xyz), and KlimaDAO gets BCT. Because the BCT ends up in KlimaDAO’s “black hole for carbon”, the supply of BCT in the market is reduced, and its price is driven up. This price pressure incentivizes carbon offset purchasers to adopt climate friendly practices rather than buy their way into satisfying carbon emissions requirements. And it encourages carbon offset producers to produce more carbon offsets.     
    </p>
    <br />
    <h4>Why not mint with KLIMA directly? </h4>
    <p>
      Requiring the buyer to mint the NFT with BCT means that the buyer must acquire BCT off of the market. As described above, this BCT eventually ends up in the KlimaDAO treasury and out of circulation. If instead the buyer could mint with KLIMA directly, they could just swap for KLIMA at an exchange and never need to touch BCT. In that case, no BCT is necessarily removed from circulation, and the pricing pressure from diminished supply of BCT does not happen.    
    </p>
    <br />
    <h4>How do I get BCT?</h4>
    <p>
      All the action takes place on the Polygon network. You can use a bridge such as <a href="https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=1">Synapseprotocol.com</a> to move funds onto Polygon. Once you are on Polygon, you can swap your funds for BCT on an exchange like <a href="https://app.sushi.com">Sushi.com</a>. 
    </p>
    <br />
    <h4>Why did you choose a rock and not a tree as the first carbonized NFT?</h4>
    <p>
      A tree or some kind of plant or vegetation is what we had originally planned on. But as we were looking at various concepts, something about a rock just vibed. (You can read more about the inspiration of The Genesis Stone on the landing page).
    </p>
    <br />
    <h4>Would it be possible to have the NFT change depending on how much BCT is used to mint?</h4>
    <p>
      We actually started with this idea in mind. But we wanted to launch sooner than later and decided to go with a simpler carbonizing mechanic. That said, this concept is definitely something we will be exploring and experimenting with in future launches.   
    </p>
    <br />
    <h4>Why not burn tokenized carbon offsets during the mint?</h4>
    <p>
      This is something we will be looking into for future launches. However, one benefit of the current minting mechanic is that the buyer doesn’t have to pay extra gas costs or worry about the bonding discount and that means the creator doesn’t have to sacrifice earnings. It is a win-win for both parties because KlimaDAO commits to keeping BCT out of circulation. 
    </p>
    <br />
  </div>
</template>
